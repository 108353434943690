<template>
  <!-- ? This will be in loop => So consider below markup for single item -->
  <b-col cols="12">

    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
    <div class="d-none d-lg-flex">
      <b-row class="flex-grow-1 px-1">
        <!-- Single Item Form Headers -->
        <b-col
          cols="12"
          lg="5"
        >
          Item
        </b-col>
        <b-col
          cols="12"
          lg="3"
        >
          Custom Price
        </b-col>
        <b-col
          cols="12"
          lg="2"
        >
          Qty
        </b-col>
        <b-col
          cols="12"
          lg="2"
        >
          Total
        </b-col>
      </b-row>
      <div class="form-item-action-col" />
    </div>

    <!-- Form Input Fields OR content inside bordered area  -->
    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
    <div class="d-flex border rounded">
      <b-row class="flex-grow-1 p-2">
        <!-- Single Item Form Headers -->
        <b-col
          cols="12"
          lg="5"
        >
          <!-- <label class="d-inline d-lg-none">{{ value.name }}</label>
          <p>{{ value.name }}</p> -->

          <SmallProductBox :product="productItem" />

        </b-col>
        <b-col
          cols="12"
          lg="3"
        >
          <label class="d-inline d-lg-none">Custom price</label>
          <b-form-input
            v-model="purchaseItems[index].amount"
            type="number"
            number
            class="mb-2"
            :readonly="staticc"
          />
        </b-col>
        <b-col
          cols="12"
          lg="2"
        >
          <label class="d-inline d-lg-none">Qty</label>
          <b-form-input
            v-model="purchaseItems[index].qty"
            type="number"
            number
            class="mb-2"
            :readonly="staticc"
            @change="(v) => $emit('qtyChange', v)"
          />
        </b-col>
        <b-col
          cols="12"
          lg="2"
        >
          <label class="d-inline d-lg-none">Total</label>
          <p class="mb-1">
            ${{ (purchaseItems[index].amount * purchaseItems[index].qty).toFixed(2) }}
          </p>
        </b-col>

      </b-row>
      <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
        <feather-icon
          :id="`form-item-delete-icon-${index}`"
          size="16"
          icon="XIcon"
          class="cursor-pointer"
          @click="onBtnClick"
        />
        <feather-icon
          :id="`form-item-settings-icon-${index}`"
          size="16"
          icon="Edit3Icon"
          class="cursor-pointer"
        />

        <!-- Setting Item Form -->
        <b-popover
          :ref="`form-item-settings-popover-${index}`"
          :target="`form-item-settings-icon-${index}`"
          triggers="click"
          placement="lefttop"
        >
          <b-form @submit.prevent>
            <b-row>

              <!-- Field: Discount -->
              <b-col cols="12">
                <b-form-group
                  label="Custom Identifier"
                  :label-for="`setting-item-${index}-discount`"
                >
                  <b-form-input
                    :id="`setting-item-${index}-discount`"
                    v-model="purchaseItems[index].customItemIdentifer"
                    type="text"
                  />
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                class="d-flex justify-content-between mt-1"
              >
                <b-button
                  variant="outline-secondary"
                  @click="clearCustomIdentifier(index)"
                >
                  Clear & Cancel
                </b-button>
                <b-button
                  variant="outline-primary"
                  @click="addCustomIdentifier(index)"
                >
                  Done
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-popover>

        <b-popover
          v-if="staticc"
          ref="popover"
          variant="danger"
          :target="`form-item-delete-icon-${index}`"
          triggers="click"
          :show.sync="popoverShow"
          placement="auto"

          container="my-container"
        >

          <template #title>
            <span>Sure to return the selected item?</span>
          </template>

          <div>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="secondary"
              class="mr-1"
              @click="onClose"
            >
              No
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="danger"
              @click="onDelete"
            >
              Return the item
            </b-button>
          </div>
        </b-popover>

      </div>
    </div>
  </b-col>
</template>

<script>
import {
  BRow, BCol, BFormInput, BPopover, BButton, BFormGroup, BForm,
} from 'bootstrap-vue'
import { isDef } from '@/assets/helpers'
import Ripple from 'vue-ripple-directive'
import { saleItemModelField } from '@/api/field_model'
import SmallProductBox from '../../search/components/SmallProductBox.vue'

export default {
  components: {
    BRow, BCol, BFormInput, SmallProductBox, BPopover, BButton, BFormGroup, BForm,
  },
  directives: {
    Ripple,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    staticc: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: null,
    },
    purchaseItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      popoverShow: false,
    }
  },
  computed: {
    productItem() {
      let v = {}
      if (this.staticc) {
        v = this.value.item
        if (isDef(this.value.storeItem) && !this.isEmpty(this.value.storeItem) && isDef(this.value.storeItem.branch)) {
          v.branchName = this.value.storeItem.branch.name
          v.branchType = this.value.storeItem.branch.type
        } else if (isDef(this.value.item.branch)) {
          v.branchName = this.value.item.branch.name
          v.branchType = this.value.item.branch.type
        }
      } else {
        v = this.value
      }
      return v
    },
  },
  methods: {
    onClose() {
      this.popoverShow = false
    },
    addCustomIdentifier(index) {
      this.$refs[`form-item-settings-popover-${index}`].$emit('close')
    },
    clearCustomIdentifier(index) {
      this.$refs[`form-item-settings-popover-${index}`].$emit('close')
      this.purchaseItems[index][saleItemModelField.customItemIdentifer] = ''
    },
    onBtnClick() {
      if (!this.staticc) {
        this.onDelete()
      }
    },
    onDelete() {
      this.onClose()
      this.$emit('remove', this.index, this.value)
    },
    isEmpty(obj) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) return false
      }
      return true
    },
  },
}
</script>

<style scoped>

</style>
