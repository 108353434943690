<template>
  <div>
    <b-button
      id="select-branch-type"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      size="sm"
      variant="primary"
      @click="onAddItemClick"
    >
      Add Item
    </b-button>

    <b-popover
      v-if="showSelectBranchType"
      ref="popover"
      target="select-branch-type"
      :show.sync="showSelectBranch"
      triggers="click"
      placement="auto"
      container="my-container"
    >
      <template v-slot:title>
        <div class="d-flex justify-content-between align-items-center">
          <span style="margin-right: 1rem;">Select Branch Type</span>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="close"
            variant="transparent"
            aria-label="Close"
            @click="onClose"
          >
            <span
              class="d-inline-block text-white"
              aria-hidden="true"
            >&times;</span>
          </b-button>
        </div>
      </template>

      <div>
        <b-form-group>
          <v-select
            v-model="selected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="option"
            placeholder="Select"
          />
        </b-form-group>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          variant="danger"
          class="mr-1"
          @click="onClose"
        >
          Cancel
        </b-button>

      </div>
    </b-popover>
  </div>
</template>

<script>
import {
  BButton, BPopover, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AuthMixin from '@/mixins/authMixin'
import vSelect from 'vue-select'
import { branchTypeField } from '@/api/field_model'

export default {
  components: {

    BPopover,
    BFormGroup,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [AuthMixin],
  data() {
    return {
      selected: null,
      option: [
        {
          title: 'Shop',
          value: branchTypeField.store,
        },
        {
          title: 'Warehouse',
          value: branchTypeField.stock,
        },
      ],
      showSelectBranch: false,
    }
  },
  computed: {
    showSelectBranchType() {
      if (this.isSales) {
        return false
      }

      if (this.isAdmin && this.isMultipleBranchMode) {
        return true
      }

      if (this.isStockKeeper && this.isMultipleBranchMode && this.isAllowedToSeeAllBranches) {
        return true
      }

      return false
    },
  },
  watch: {
    selected(v) {
      if (v) {
        this.onClose()
        this.selected = null
        this.$emit('selectedType', v.value)
      }
    },
  },
  methods: {
    onAddItemClick() {
      if (this.isSales || (this.isAdmin && !this.isMultipleBranchMode) || !this.isAllowedToSeeAllBranches) {
        this.$emit('selectedType', null)
      } else if (this.isStockKeeper && !this.isMultipleBranchMode) {
        this.$emit('selectedType', null)
      }
    },
    onClose() {
      this.showSelectBranch = false
    },
  },
}
</script>

<style  lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
