<template>
  <section
    v-show="show"
    class="invoice-add-wrapper"
  >
    <b-row class="invoice-add">

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-form @submit.prevent>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <h3 class="text-primary invoice-logo mr-1">
                      Add New Sale
                    </h3>
                    <b-row>
                      <b-col>
                        <b-badge
                          :variant="buildPaymentStatusColor(purchaseModel.creditStatus)"
                          class="badge-glow"
                        >
                          {{ buildPaymentStatusText(purchaseModel.creditStatus) }}
                        </b-badge>
                      </b-col>
                      |
                      <b-col>
                        <b-badge
                          variant="light-primary"
                        >
                          {{ $route.params.branch.name }} - Branch
                        </b-badge>
                      </b-col>
                    </b-row>

                  </div>
                </div>

                <!-- Header: Right Content -->
                <div class="invoice-number-date mt-md-0 mt-2">
                  <div class="d-flex align-items-center">
                    <span class="title">
                      Date:
                    </span>
                    <flat-pickr
                      v-model="purchaseModel.createdAt"
                      :config="createdAtConfig"
                      class="form-control invoice-edit-input"
                    />
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Items Section -->
            <b-card-body class="invoice-padding form-item-section">
              <div
                ref="form"
                class="repeater-form"
                :style="{height: trHeight}"
              >
                <b-row
                  v-for="(item, index) in itemModelList"
                  :key="keyChange + '_' + index"
                  ref="row"
                  class="pb-2"
                >

                  <!-- Item Form -->
                  <ProductSaleBox
                    :value="item"
                    :purchase-items="purchaseModel.items"
                    :index="index"
                    @remove="removeItem"
                  />
                </b-row>
              </div>

              <AddItemBtn @selectedType="onAddItemClick" />

            </b-card-body>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>

                <!-- Col: Sales Persion -->
                <b-col
                  cols="12"
                  md="4"
                  class="mt-md-0 mt-3"
                  order="1"
                  order-md="1"
                >
                  <!-- <label
                    for="invoice-data-sales-person"
                    class="text-nowrap mr-50"
                  >Sales Person:</label>
                  <b-form-input
                    id="invoice-data-sales-person"
                    v-model="purchaseModel.salesPerson"
                    placeholder="Edward Crowley"
                  /> -->
                  <div
                    v-if="isDefAndNotEmpty(itemModelList)"
                    class="d-flex justify-content-between align-items-center"
                  >
                    <label for="patymentTerms">Including Tax?</label>
                    <b-form-checkbox
                      id="patymentTerms"
                      v-model="purchaseModel.includeVat"
                      switch
                      @change="onTaxChange"
                    />
                  </div>
                </b-col>

                <!-- Col: Total -->
                <b-col
                  v-if="isDefAndNotEmpty(itemModelList)"
                  cols="12"
                  md="8"
                  class="mt-md-6 d-flex justify-content-end"
                  order="2"
                  order-md="2"
                >
                  <div
                    class="invoice-total-wrapper"
                    style="max-width: 20rem;"
                  >
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        {{ purchaseModel.includeVat ? `Total before Tax` : 'Total' }}:
                      </p>
                      <p class="invoice-total-amount">
                        {{ calcTotalAmount.toFixed(2) }}
                      </p>
                    </div>
                    <div
                      v-if="purchaseModel.includeVat"
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title">
                        {{ taxLabelWithPercentage }}:
                      </p>
                      <p class="invoice-total-amount">
                        {{ calcVat.toFixed(2) }}
                      </p>
                    </div>
                    <hr
                      v-if="purchaseModel.includeVat"
                      class="my-50"
                    >
                    <div
                      v-if="purchaseModel.includeVat"
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title">
                        Total with {{ taxLabelWithPercentage }}:
                      </p>
                      <p class="invoice-total-amount">
                        {{ calcTotalPlusVat.toFixed(2) }}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">Note: </span>
              <b-form-textarea v-model="purchaseModel.note" />
            </b-card-body>

          </b-card>
        </b-form>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >

        <!-- Action Buttons -->
        <b-card>

          <!-- Button: Send Invoice -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :variant="!isFail ? 'primary' : 'danger'"
            :loading="loading"
            :disabled="loading"
            block
            @click="handleSale"
          >
            Sale
          </b-button>

        </b-card>

        <!-- Payment Method -->
        <div class="mt-2">
          <b-form-group
            label="Select Payment method"
            label-for="payment-method"
          >

            <v-select
              v-model="purchaseModel.type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="paymentMethods"
              placeholder="Select"
              label="label"
              input-id="payment-method"
              class="payment-selector"
              :clearable="false"
              @input="onPaymentMethodChange"
            />

          </b-form-group>

          <!-- advance credit and cheque input -->

          <b-card
            no-body
          >
            <b-card-body
              v-show="purchaseType == saleTypeField.cheque"
              style="    padding: 1rem;"
            >
              <b-form-input
                id="chequeNo"
                v-model="purchaseModel.chequeNo"
                placeholder="Enter Cheque No"
              />

            </b-card-body>
            <b-card-body
              v-show="purchaseType == saleTypeField.credit"
              style="    padding: 1rem;"
            >
              <b-form-group>
                <b-input-group>
                  <b-form-input
                    id="manufacturer"
                    v-model="purchaseModel.creditPayedAmount"
                    placeholder="Enter Paid Amount"
                    type="number"
                    number
                    @change="handleActualStatusColor"
                  />
                  <b-input-group-append>
                    <b-button
                      :variant=" !(purchaseModel.creditPayedAmount >
                        actualTotal) ? buildPaymentStatusColor(
                        purchaseModel.creditStatus) : 'danger'"
                      disabled
                      class="btn-icon"
                    >
                      {{
                        !(purchaseModel.creditPayedAmount >
                          actualTotal)
                          ? (actualTotal -
                            purchaseModel.creditPayedAmount ||
                            0) : 'Xid'

                      }}
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group>

                <flat-pickr
                  v-model="purchaseModel.returnDate"
                  class="form-control"
                  placeholder="Select credit return date"
                  :config="returnDateConfig"
                />
              </b-form-group>
            </b-card-body>

          </b-card>

        </div>

        <!-- customer info -->
        <div class="mt-2">
          <b-form-group
            label="Sold To:"
          >
            <b-card no-body>
              <b-card-body style="padding: 1rem 0px 0px 0px;">

                <b-col
                  cols="12"
                  xl="12"
                  class="mb-lg-1"
                >

                  <!-- Select Customer -->
                  <CustomerSelect @selectedData="onCustomerSelect" />

                  <!-- Selected Customer -->
                  <div
                    v-if="purchaseModel.customerName"
                    class="mt-1"
                  >
                    <h6 class="mb-25">
                      {{ purchaseModel.customerName }}
                    </h6>
                    <b-card-text class="mb-25">
                      {{ purchaseModel.customerPhoneNo }}
                    </b-card-text>
                  </div>
                </b-col>
              </b-card-body>

            </b-card>

          </b-form-group>

        </div>
      </b-col>
    </b-row>
    <search-product
      ref="searchProductRef"
      :selected-items-list="selectedItemsList"
      :custom-branch-id="customBranchId"
      :is-shop="isShop"
      @onMultipleSelect="onMultipleSelect"
    />
    <add-edit-tax
      ref="addEditTax"
      @onFinish="onFinish"
    />
  </section>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BFormCheckbox, VBToggle, BInputGroup, BInputGroupAppend, BBadge, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import {
  branchTypeField, companyModelField, creditStatusField, saleModelField, saleTypeField,
} from '@/api/field_model'
import {
  defaultPurchaseModel,
} from '@/api/default_models'
import {
  isDef, isDefAndNotEmpty, parseDate,
} from '@/assets/helpers'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import PurchaseCtr from '@/api/purchase_ctr'
// import { isApiSuccess } from '@/api/ajax'
import AuthMixin from '@/mixins/authMixin'
import { Ajax, isApiSuccess, RouteConst } from '@/libs/ajax'
import AddEditTax from '../addEditTex/addEditTax.vue'
import AddItemBtn from './components/AddItemBtn.vue'
import CustomerSelect from '../collections/CustomerSelect.vue'
import ProductSaleBox from './components/ProductSaleBox.vue'
import SearchProduct from '../search/searchProduct.vue'
import {
  buildPaymentStatusColor,
  buildPaymentStatusText,
  buildPaymentTypeText,
} from './saleHelper'

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    flatPickr,
    vSelect,
    SearchProduct,
    BInputGroup,
    BInputGroupAppend,
    ProductSaleBox,
    CustomerSelect,
    AddItemBtn,
    BFormTextarea,
    AddEditTax,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition, AuthMixin],
  data() {
    return {
      purchaseModel: { ...defaultPurchaseModel },
      itemModelList: [],
      isVerticalMenuCollapsed: this.$store.state.verticalMenu.isVerticalMenuCollapsed,
      keyChange: 0,
      show: true,
      isShop: false,
      isFail: false,
      customBranchId: null,
      loading: false,
      createdAtConfig: {
        dateFormat: 'Y-m-d h:i',
        enableTime: true,
        defaultDate: new Date(Date.now()),
        allowInput: true,
        maxDate: new Date(Date.now()),
      },
      returnDateConfig: {
        allowInput: true,
        dateFormat: 'Y-m-d h:i',
        enableTime: true,
        altInput: true,
        minDate: new Date(Date.now()),
      },
      showSearchProduct: false,
      isDefAndNotEmpty,
      buildPaymentStatusColor,
      buildPaymentStatusText,
      saleTypeField,
      buildPaymentTypeText,
      paymentMethods: [
        {
          label: 'Cash (Full payment)',
          value: saleTypeField.cash,
        },
        {
          label: 'Credit',
          value: saleTypeField.credit,
        },
        {
          label: 'Cheque',
          value: saleTypeField.cheque,
        },
      ],
    }
  },
  computed: {
    purchaseType() {
      return this.purchaseModel.type.value
    },
    calcTotalAmount() {
      let totalAmount = 0
      if (isDefAndNotEmpty(this.purchaseModel.items)) {
        for (let i = 0; i < this.purchaseModel.items.length; i += 1) {
          const element = this.purchaseModel.items[i]

          const qty = (element.qty ? element.qty : 0)

          const amount = element.amount
            ? parseFloat(element.amount || 0)
            : 0
          totalAmount += Number(amount * qty)
        }
      }
      return totalAmount
    },
    calcVat() {
      return this.calcVatFn(this.calcTotalAmount)
    },
    calcTotalPlusVat() {
      return (this.calcTotalAmount || 0) + (this.calcVat || 0)
    },

    calcStockTotalAmount() {
      let totalAmount = 0
      if (isDefAndNotEmpty(this.purchaseModel.items)) {
        for (let i = 0; i < this.purchaseModel.items.length; i += 1) {
          const element = this.purchaseModel.items[i]
          if (!element.storeId) {
            const qty = (element.qty ? element.qty : 0)

            const amount = element.amount
              ? parseFloat(element.amount || 0)
              : 0
            totalAmount += Number(amount * qty)
          }
        }
      }
      return this.purchaseModel.includeVat ? totalAmount + (totalAmount * 0.15) : totalAmount
    },
    actualTotal() {
      return this.purchaseModel.includeVat ? this.calcTotalPlusVat : this.calcTotalAmount
    },
    calcUnpaidAmount() {
      let unpaid = 0
      if (this.purchaseModel.creditStatus !== creditStatusField.payed
        || this.purchaseType === saleTypeField.credit) {
        unpaid = this.actualTotal - this.purchaseModel.creditPayedAmount
      }
      return unpaid
    },
    selectedItemsList() {
      const data = this.purchaseModel.items.filter(e => (this.isShop ? isDef(e.storeId) : !isDef(e.storeId)))

      return data.map(v => ({ purchaseItems: v, itemModel: v.itemModel }))
    },

  },
  mounted() {
    this.initTrHeight()
    this.handleTax()
    this.$store.commit('searchItems/CLEAR_STATE')
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
    if (!this.$route.params.branchId) {
      this.$router.back()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notification',
          icon: 'BellIcon',
          text: 'Please select branch first!',
          variant: 'warning',
        },
      })
    }
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', this.isVerticalMenuCollapsed)
  },
  methods: {
    handleTax() {
      this.purchaseModel.includeVat = this.companyInfo[companyModelField.taxPercentage] && this.companyInfo[companyModelField.taxPercentage] !== 0
    },
    onFinish(v) {
      console.debug('onFinish', v)
      this.purchaseModel.includeVat = true
    },
    onTaxChange(v) {
      // console.debug('onTaxChange', v)

      if (!this.companyInfo[companyModelField.taxPercentage] || this.companyInfo[companyModelField.taxPercentage] === 0) {
        this.purchaseModel.includeVat = false
        this.$refs.addEditTax.show = true
      } else {
        this.purchaseModel.includeVat = v
      }
    },
    onCustomerSelect(customer) {
      this.purchaseModel.customerId = customer.id
      this.purchaseModel.customerName = customer.name
      this.purchaseModel.customerPhoneNo = customer.phone
    },
    async handleSale() {
      this.isFail = false
      const { purchaseModel } = this
      let purchaseModelCopy = { ...purchaseModel }

      if (!purchaseModelCopy.items || !isDefAndNotEmpty(purchaseModelCopy.items)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Fail',
            icon: 'InfoIcon',
            text: 'At least one product is required',
            variant: 'danger',
          },
        },
        {
          position: 'top-center',
        })
        return
      }
      this.loading = true
      const dateSelected = parseDate(new Date(Date.now()))
      purchaseModelCopy.createdAt = parseDate(purchaseModelCopy.createdAt || dateSelected)
      purchaseModelCopy.adminId = this.userData.id
      purchaseModelCopy.items = JSON.stringify({
        items: purchaseModelCopy.items.map(v => ({
          amount: v.amount,
          itemId: v.itemId,
          oId: v.originalId,
          purchasePrice: v.purchasePrice,
          qty: v.qty,
          customItemIdentifer: v.customItemIdentifer || null,
          branchType: null,
        })),
      })
      purchaseModelCopy.totalAmount = this.actualTotal
      purchaseModelCopy.stockTotal = this.calcStockTotalAmount
      purchaseModelCopy.branchId = this.$route.params.branchId
      purchaseModelCopy.unpaidAmount = this.calcUnpaidAmount
      purchaseModelCopy.type = this.purchaseType
      purchaseModelCopy[saleModelField.includeVat] = this.purchaseModel.includeVat
      purchaseModelCopy[saleModelField.taxPercentage] = purchaseModelCopy[saleModelField.includeVat] ? this.companyInfo[companyModelField.taxPercentage] : 0

      purchaseModelCopy[saleModelField.taxLabel] = purchaseModelCopy[saleModelField.includeVat] ? this.companyInfo[companyModelField.taxField] : ''

      if (isDef(purchaseModelCopy.creditPayedAmount)
        && purchaseModelCopy.creditPayedAmount !== 0 && purchaseModelCopy.creditPayedAmount !== '') {
        purchaseModelCopy.creditPaymentAmountHistory.push({
          amount: purchaseModelCopy.creditPayedAmount,
          createdAt: dateSelected,
        })
      }
      purchaseModelCopy.creditPaymentAmountHistory = JSON.stringify({
        creditPaymentAmountHistory: purchaseModelCopy.creditPaymentAmountHistory,
      })
      // console.debug(purchaseModelCopy)

      try {
        const res = await Ajax(RouteConst.addNewPurchaseToDBUrl, {
          body: { saleModel: purchaseModelCopy },
        })
        this.loading = false
        if (isApiSuccess(res)) {
          this.$swal({
            title: 'Done',
            text: 'Successfully sale',
            icon: 'success',
            showCancelButton: true,
            confirmButtonText: 'Keep sale',
            cancelButtonText: 'Show sale List',
            // html:
            // `
            // <button type="button" class="btn btn-outline-info">128390</button>
            // `,
            customClass: {
              confirmButton: 'btn btn-outline-secondary',
              cancelButton: 'btn btn-primary ml-2',
            },
            buttonsStyling: false,
          }).then(result => {
            const reset = () => {
              purchaseModelCopy = { ...defaultPurchaseModel }
              purchaseModelCopy.creditPaymentAmountHistory.length = 0
              this.itemModelList = []
              this.purchaseModel.creditPaymentAmountHistory.length = 0
              this.purchaseModel = { ...defaultPurchaseModel }
              this.$refs.searchProductRef.searchQuery = ''
              this.$refs.searchProductRef.selectedItems = []
            }
            if (result.value) {
              reset()
              this.initTrHeight()
              this.show = false
              this.$store.commit('searchItems/CLEAR_STATE')
              this.$nextTick(() => {
                this.show = true
              })
            } else if (result.dismiss === 'cancel') {
              reset()
              this.$router.push('/sale-list')
            } else {
              reset()
              this.initTrHeight()
              this.show = false
              this.$store.commit('searchItems/CLEAR_STATE')
              this.$nextTick(() => {
                this.show = true
              })
            }
          })
        } else {
          this.isFail = true
        }
      } catch (error) {
        console.debug(error)
        console.debug('error.message', error.message)
        console.debug(error.message === 'Network Error')
        this.isFail = true
        this.loading = false
      }
    },
    handleActualStatusColor(v) {
      const { purchaseModel } = this
      if (isDef(v) && v !== '') {
        purchaseModel.creditPayedAmount = parseFloat(v)
        if (purchaseModel.creditPayedAmount === 0
          && this.purchaseType === saleTypeField.credit) {
          purchaseModel.creditStatus = creditStatusField.unPayed
        } else if (purchaseModel.creditPayedAmount >= this.actualTotal) {
          purchaseModel.creditStatus = creditStatusField.payed
        } else if (purchaseModel.creditPayedAmount < this.actualTotal
          && this.purchaseType === saleTypeField.credit) {
          purchaseModel.creditStatus = creditStatusField.advance
        } else {
          purchaseModel.creditStatus = creditStatusField.payed
        }
      } else {
        purchaseModel.creditPayedAmount = 0
      }
    },
    onPaymentMethodChange(v) {
      // console.debug(v)
      if (v.value === saleTypeField.credit) {
        this.purchaseModel.creditStatus = creditStatusField.unPayed
        this.purchaseModel.chequeNo = ''
      } else {
        this.purchaseModel.creditStatus = creditStatusField.payed
        this.purchaseModel.creditPayedAmount = 0
      }
    },
    onMultipleSelect(v) {
      // eslint-disable-next-line prefer-const
      let { purchaseModel, itemModelList } = this
      if (isDefAndNotEmpty(v)) {
        // console.debug('purchaseModel.items', purchaseModel.items)
        if (isDefAndNotEmpty(purchaseModel.items)) {
          v.forEach(element => {
            const index = purchaseModel.items.findIndex(
              element1 => (element1.itemId === (element.itemModel ? element.itemModel.id : null)),
            )

            if (element.itemModel && isDef(element.itemModel.id)) {
              // console.debug('index🪂', index)
              // console.debug('index🪂', purchaseModel.items[index])

              if (index === -1) {
                // eslint-disable-next-line no-param-reassign
                element.purchaseItems.itemModel = element.itemModel
                purchaseModel.items.push(element.purchaseItems)
                itemModelList.push(element.itemModel)

                purchaseModel.creditPayedAmount = 0
                this.$refs.form.style.overflow = 'hidden'
                this.$nextTick(() => {
                  this.trAddHeight(this.$refs.row[0].offsetHeight)
                  setTimeout(() => {
                    this.$refs.form.style.overflow = null
                  }, 350)
                })
              } else {
                purchaseModel.items[index] = element.purchaseItems
                this.keyChange += 1
              }
            }
          })
        } else {
          purchaseModel.items = []
          v.forEach(z => {
            // eslint-disable-next-line no-param-reassign
            z.purchaseItems.itemModel = z.itemModel
          })
          purchaseModel.items = v.map(e => e.purchaseItems)
          purchaseModel.creditPayedAmount = 0
          this.itemModelList = v.map(e => e.itemModel)
          for (let i = 0; i < this.itemModelList.length; i += 1) {
            this.$refs.form.style.overflow = 'hidden'
            this.$nextTick(() => {
              this.trAddHeight(this.$refs.row[0].offsetHeight)
              setTimeout(() => {
                this.$refs.form.style.overflow = null
              }, 350)
            })
          }
        }
      }
    },
    onAddItemClick(v) { // branchType
      const isActualMultiple = !this.isMultipleBranchMode && (this.isAllowedToSeeAllBranches || this.isAdmin)
      console.debug('v', v)
      // v is coming if to sell from shop or warehouse
      console.debug('this.$route.params.', this.$route.params)

      this.isShop = (this.legalBranch && !v) ? this.$route.params.branch.type === branchTypeField.store : (v === branchTypeField.store || (isActualMultiple ? this.$route.params.branch.type === branchTypeField.store : false))

      this.customBranchId = this.legalBranch || (isActualMultiple ? this.$route.params.branchId : null)

      if (v) { // multipleBranchMode
        this.$store.commit('searchItems/CLEAR_STATE')
        this.$refs.searchProductRef.searchQuery = ''
        this.$refs.searchProductRef.selectedItems = this.selectedItemsList
        this.handleSelectProduct()
      } else {
        // console.debug('--- 2 --')
        // this.isShop = true
        this.customBranchId = this.legalBranch || (!this.isMultipleBranchMode && (this.isAllowedToSeeAllBranches || this.isAdmin) ? this.$route.params.branchId : null)
        this.handleSelectProduct()
      }
    },
    handleSelectProduct() {
      this.$refs.searchProductRef.showHide = true
    },
    removeItem(index, item) {
      // console.debug(index, item)
      const itemIndex = this.itemModelList.indexOf(item)
      this.itemModelList.splice(itemIndex, 1)
      this.purchaseModel.items.splice(`${index}`.split('_').pop(), 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}

.invoice-actions {
  position: sticky;
  top: 70px;
  height: 199px;
}
</style>
