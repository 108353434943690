<template>
  <section class="ecommerce-application">
    <div class="list-view product-checkout">
      <div class="checkout-items">
        <b-card
          class="ecommerce-card"
          no-body
        >

          <!-- Product Image -->
          <div class="item-img">
            <b-link>
              <b-img
                :src="image"
                height="90px"
                rounded
                style="width: 90px"
                :alt="`${product.name}-${product.id}`"
              />
            </b-link>
          </div>

          <!-- Product Details: Card Body -->
          <b-card-body>
            <div class="item-name">
              <h6 class="mb-0">
                <b-link class="text-body">
                  {{ product.name }}
                </b-link>
              </h6>

            </div>
            <span
              class="mb-1"
              :class="product.branchType === '1' ? 'text-primary' : 'text-success'"
            >{{ product.branchName }} {{ product.branchType === '1' ? '(Warehouse)' : '(Shop)' }} </span>
            <span class="delivery-date text-muted">Price: {{ product.unitPrice }}</span>
          </b-card-body>

          <!-- Product Options/Actions -->
          <div class="item-options text-center">
            <div class="item-wrapper">
              <div class="item-cost">
                <h4 class="item-price">
                  {{ product.qty }}<span
                    v-if="qtyToBeDeducted"
                    class="text-danger"
                  >(-{{ qtyToBeDeducted }})</span>
                </h4>
              </div>
            </div>
            <!-- <b-button
          variant="light"
          class="mt-1 remove-wishlist"
          @click="removeProductFromCartClick(product)"
        >
          <feather-icon
            icon="XIcon"
            class="mr-50"
          />
          <span>Remove</span>
        </b-button> -->
            <b-button
              variant="primary"
              class="btn-cart move-cart"
              @click="$emit('choose', product)"
            >
              <!-- Edit3Icon -->
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="text-nowrap">Add</span>
            </b-button>
          </div>
        </b-card>
      </div>
    </div>
  </section>

</template>

<script>
import {
  BCard, BCardBody, BLink, BImg, BButton,
} from 'bootstrap-vue'
import { isDef, noImage } from '@/assets/helpers'

export default {
  components: {
    BCard, BCardBody, BLink, BImg, BButton,
  },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    qtyToBeDeducted: {
      type: Number,
      default: null,
    },
  },
  computed: {
    image() {
      return isDef(this.product.image) && this.product.image !== '' ? this.product.image : noImage
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>
