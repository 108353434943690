<template>
  <div>
    <b-sidebar
      id="search-product"
      ref="sideBarRef"
      v-model="showHide"
      :aria-expanded="`'${showHide}'`"
      aria-controls="search-product"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop-variant="info"
      shadow
      backdrop
      no-header
      right
    >
      <template #default="{ hide }">
        <StockModals
          ref="stockModals"
          :type="actionType"
          :text-value="textValue"
          :is-for-sale="true"
          :default-value="defaultValue"
          :is-minus="true"
          :is-still="false"
          @onStockInAndOut="onInputChange"
        />

        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Search product
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />

        </div>

        <!-- Body -->
        <b-form
          class="p-2"
          @submit.prevent
        >

          <!-- Customer Name -->
          <b-row>
            <b-col cols="10">
              <b-form-group>
                <b-form-input
                  v-model="searchQuery"
                  trim
                  placeholder="Search..."
                />
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :variant="alphabetically ? 'primary' : 'secondary'"
                class="btn-icon rounded-circle"
                @click="changeToAlphabetically"
              >
                <feather-icon icon="ArchiveIcon" />
              </b-button>
            </b-col>
          </b-row>

          <b-overlay
            id="overlay-background"
            :show="isLoading"
            variant="blur"
            :opacity="0.5"
            blur="2px"
            rounded="sm"
          >
            <ProductBox
              v-for="(product, index) in searchResults"
              :key="keyChange + '_' + index"
              :product="product"
              :qty-to-be-deducted="getSelectedItemQty(product)"
              @choose="choose"
            />
          </b-overlay>

        </b-form>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ from }} to {{ to }} of {{ of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </template>
      <template #footer>
        <div class="p-2 border-top cursor-pointer d-flex flex-wrap align-items-center">
          <div
            class="text-primary mr-1"
            style="font-size: 25px; font-weight: 800;"
          >
            {{ sumOfSelectedProductQty }}
          </div>
          <div class="pl-1 border-left mr-3">
            Quantity
          </div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="onSubmit"
          >
            Done
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="$refs.sideBarRef.hide()"
          >
            Cancel
          </b-button>
        </div>

      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BPagination, BCol, BOverlay, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { historyTypeField } from '@/api/field_model'
import { mapState } from 'vuex'
import { LogicStateStatus } from '@/libs/ajax'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import ProductBox from './components/ProductBox.vue'
import { debounce, isDef, perPage } from '../../assets/helpers'
import StockModals from '../../components/StockModals.vue'

export default {
  components: {
    BSidebar, BForm, BFormGroup, BFormInput, BButton, ProductBox, BPagination, BCol, BOverlay, BRow, StockModals,
  },
  directives: {
    Ripple,
  },
  props: {
    customBranchId: {
      type: [Number, String],
      default: null,
    },
    isShop: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      searchQuery: '',
      actionType: historyTypeField.stockOut,
      defaultValue: null,
      textValue: null,
      editableId: null,
      keyChange: 0,
      alphabetically: false,
      showHide: false,
      selectedItems: [],
      currentPage: 1,
      perPage,
    }
  },
  computed: {
    sumOfSelectedProductQty() {
      let q = 0
      if (this.selectedItems && this.selectedItems.length > 0) {
        this.selectedItems.forEach(element => {
          q += element.purchaseItems.qty
        })
      }
      return q
    },
    from() {
      return this.perPage * (this.currentPage - 1) + (this.searchResults.length ? 1 : 0)
    },
    to() {
      return this.perPage * (this.currentPage - 1) + this.searchResults.length
    },
    of() {
      return this.total
    },
    ...mapState({
      searchResults: state => state.searchItems.items,
      total: state => state.searchItems.total,
      isLoading: state => state.searchItems.logicStateStatus === LogicStateStatus.loading,
    }),
  },

  watch: {
    searchQuery: debounce(function (val) {
      const thiss = this
      if (val && val !== '') {
        thiss.searchItems({ refresh: true })
      } else {
        thiss.$store.commit('searchItems/CLEAR_STATE')
      }
    }, 500),
    currentPage() {
      if (this.searchQuery !== '') {
        this.searchItems({})
      }
    },
  },
  mounted() {
    // eslint-disable-next-line no-extend-native
    Array.prototype.insert = (index, item) => {
      this.splice(index, 0, item)
    }
  },
  methods: {
    changeToAlphabetically() {
      this.alphabetically = !this.alphabetically
      if (this.searchQuery !== '') {
        this.searchItems({ refresh: true })
      }
    },
    showToast(title, text) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'icon-alert-circle',
          text,
          variant: 'danger',
        },
      },
      {
        timeout: 2500,
        position: 'bottom-right',
      })
    },
    onSubmit() {
      this.$refs.sideBarRef.hide()
      this.$emit('onMultipleSelect', this.selectedItems)
    },
    getSelectedItemQty(itemModel) {
      let q = 0
      const selected = this.selectedItems
        .filter(element => element.purchaseItems.itemId === itemModel.id)

      if (selected && selected.length > 0) {
        q = selected[0].purchaseItems.qty
      }
      return Number(q)
    },
    onInputChange({ qty }) {
      // console.debug('qty', qty)
      const { selectedItems } = this

      const itemModel = this.searchResults.find(v => v.id === this.editableId)
      // eslint-disable-next-line no-param-reassign

      // if (currentCompanySettingModel
      //                 .companySettingModelData.restrictSellOutOfStock) {
      //               if (itemModel.qty < qty) {
      //                 AlToast.fail(
      //                   message:
      //                       t(context, 'itemBoxWidget.action.stockOut.fail'),
      //                 );
      //                 return;
      //               }
      //             }

      if (selectedItems.length > 0) {
        const index = selectedItems.findIndex(element => (element.purchaseItems.itemId) === itemModel.id)
        // console.debug(index, 'index')
        if (isDef(index) && index !== -1) {
          // selectedItems.splice(index, 1)
          this.keyChange += 1
          selectedItems[index] = {
            itemModel,
            purchaseItems: {
              originalId: itemModel.oId,
              itemId: itemModel.id,
              qty,
              amount: itemModel.unitPrice,
              location: '',
              purchasePrice: itemModel.purchasePrice,
            },
          }
        } else {
          const itemP = {
            itemModel,
            purchaseItems: {
              originalId: itemModel.oId,
              itemId: itemModel.id,
              qty,
              amount: itemModel.unitPrice,
              location: '',
              purchasePrice: itemModel.purchasePrice,
            },
          }
          selectedItems.push(itemP)
        }
      } else {
        const itemP = {
          itemModel,
          purchaseItems: {
            originalId: itemModel.oId,
            itemId: itemModel.id,
            qty,
            amount: itemModel.unitPrice,
            location: '',
            purchasePrice: itemModel.purchasePrice,
          },
        }
        selectedItems.push(itemP)
      }
    },
    choose(v) {
      this.editableId = v.id
      this.defaultValue = v.qty
      this.textValue = this.getSelectedItemQty(v)
      this.$refs.stockModals.$refs['stock-in-modal'].show()
    },
    async searchItems({ refresh = false }) {
      this.$store.dispatch('searchItems/getPaginatedSearchItems', {
        page: refresh ? 1 : this.currentPage,
        filterBy: null,
        sortBy: null,
        isShop: this.isShop,
        notify: this.showToast,
        customBranchId: this.customBranchId,
        query: this.searchQuery,
        alphabetically: this.alphabetically,
      })
        .then(() => {})
        .catch(error => {
          this.showToast('Error', error.message)
        })
    },
  },
}
</script>

<style lang="scss">
#search-product {
  width: 500px;
}
</style>
