<template>
  <b-sidebar
    id="add-tax-sidebar"
    ref="addTaxSidebar"
    v-model="show"
    :aria-expanded="`'${show}'`"
    aria-controls="add-tax-sidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Edit Tax
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <validation-provider
            #default="validationContext"
            name="Tax Name"
            rules="required"
          >

            <b-form-group>
              <b-form-input
                id="tax-name"
                v-model="taxModel.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Tax name ~VAT/TOT..."
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group>
            <b-form-input
              id="tax-rate"
              v-model="taxModel.rate"
              trim
              type="number"
              number
              placeholder="Tax rate ~15%"
            />

          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="gradient-primary"
              class="mr-2"
              :disabled="loading"
              type="submit"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              :disabled="loading"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>

import {
  BSidebar, BForm, BFormGroup, BFormInvalidFeedback, BButton, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { defaultTaxModel } from '@/api/default_models'
import { Ajax, RouteConst, isApiSuccess } from '@/libs/ajax'
import { companyModelField } from '@/api/field_model'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,

    BFormInvalidFeedback,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      show: false,
      loading: false,
    }
  },
  methods: {
    async onSubmit() {
      this.loading = true
      const body = {
        [companyModelField.taxPercentage]: this.taxModel.rate,
        [companyModelField.taxField]: this.taxModel.name,
      }
      const res = await Ajax(RouteConst.updateCompanyUrl, {
        body: { companyModel: body },
      })
      const companyInfo = { ...this.$store.state.companyModel }
      companyInfo[companyModelField.taxPercentage] = this.taxModel.rate
      companyInfo[companyModelField.taxField] = this.taxModel.name

      this.$store.commit('auth/UPDATE_COMPANY_INFO', companyInfo)

      this.$emit('onFinish', body)
      this.loading = false
      if (isApiSuccess(res)) {
        // this.$nextTick(() => {
        //   this.show = false
        // })
        this.$emit('refresh')
        this.$refs.addTaxSidebar.hide()
      }
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const taxModel = ref(JSON.parse(JSON.stringify(defaultTaxModel)))

    const resetTaxModel = () => {
      taxModel.value = JSON.parse(JSON.stringify(defaultTaxModel))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetTaxModel)

    return {
      taxModel,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
