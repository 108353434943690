<template>
  <div>
    <!-- modal -->
    <b-modal
      id="modal-prevent-closing"
      ref="stock-in-modal"
      title="How many?"
      no-close-on-backdrop
      ok-title="Submit"
      cancel-variant="outline-secondary"
      :ok-disabled="submitLoading"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-form-group
          :state="qtyState"
          label-for="qty-input"

          :invalid-feedback="invalidFeedBack"
        >
          <b-form-input
            id="qty-input"
            v-model="qty"
            placeholder="Quantity"
            :state="qtyState"
            required
            type="number"
            :min="0.001"
            number
            step="0.001"
          />
        </b-form-group>
        <span
          style="
        display: flex;
        width: 100%;
        justify-content: center;
        text-align: center;"
        >
          <h3>{{ defaultValue }}</h3>
          <feather-icon
            size="21"
            class="mr-2 ml-2"
            icon="ArrowRightIcon"
          />
          <h3 :class="isMinus ? 'text-danger' : `text-success`">{{ buildChange }}</h3></span>

        <div>

          <b-form-group
            v-if="type === historyTypeField.stockIn && (userAllowedClass.isPurchasePriceAllowed(userAllowedList) || isAdmin)"
            label-for="purchase-price-input"
          >
            <b-input-group
              append="Recommended"
            >
              <b-form-input
                id="purchase-price-input"
                v-model="purchasePrice"
                type="number"
                placeholder="Enter Purchase price"
                :min="0.000"
                number
                step="0.001"
              />
            </b-input-group>

          </b-form-group>

          <b-form-group
            v-if="type === historyTypeField.stockIn"
          >
            <flat-pickr
              v-model="expiryDate"
              placeholder="Select Expiry date"
              class="form-control"
            />
          </b-form-group>

          <CollectionSelect
            v-if="type === historyTypeField.stockIn || isTransfer"
            :tablename="tableName"
            :placeholder-o="placeholder"
            :branch-type="branchType"
            @selectedData="(v) => selected = v"
          />

          <b-form-group
            v-if="isForSale === false && (type === historyTypeField.stockIn || type === historyTypeField.stockOut)"
            label-for="note-input"
          >
            <b-form-textarea
              v-model="note"
              placeholder="Note..."
            />
          </b-form-group>

          <!-- alert -->
          <b-alert
            v-height-fade.appear
            variant="danger"
            :show="isTransfer && secondInvalidFeedBack.length > 0"
            class="mb-0 mt-1"
          >
            <div class="alert-body">
              <feather-icon
                icon="InfoIcon"
                class="mr-50"
              />
              {{ secondInvalidFeedBack }}
            </div>
          </b-alert>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormGroup, BModal, VBModal, BFormInput, BAlert, BFormTextarea, BInputGroup,
} from 'bootstrap-vue'
import {
  branchTypeField, companyModelField, historyTypeField,
} from '@/api/field_model'
import { heightFade } from '@core/directives/animations'
import flatPickr from 'vue-flatpickr-component'
import AuthMixin from '@/mixins/authMixin'
import CollectionSelect from '../views/collections/CollectionSelect.vue'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BFormTextarea,
    BAlert,
    flatPickr,
    BInputGroup,
    CollectionSelect,
  },
  directives: {
    'b-modal': VBModal,
    'height-fade': heightFade,
  },
  mixins: [AuthMixin],
  props: {
    type: {
      type: String,
      default: null,
    },
    branchType: { // BranchType
      type: String,
      default: null,
    },
    isMinus: {
      type: Boolean,
      default: false,
    },
    isStill: {
      type: Boolean,
      default: false,
    },
    isForSale: {
      type: Boolean,
      default: false,
    },
    defaultValue: {
      type: [Number, String],
      default: 0,
    },
    textValue: {
      type: Number,
      default: null,
    },
    defaultValueOnInit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      qty: 0,
      note: '',
      purchasePrice: null,
      historyTypeField,
      itemModel: null,
      itemHistoryModel: null,
      options: [],
      search: '',
      submitLoading: false,
      offset: 0,
      expiryDate: null,
      limit: 10,
      loading: false,
      invalidFeedBack: '',
      secondInvalidFeedBack: '',
      selected: '',
      qtyState: null,
    }
  },

  computed: {
    tableName() {
      let tn = ''
      if (this.type === historyTypeField.stockIn) {
        tn = 'stockIn'
      } else if (this.isTransfer) {
        tn = 'branch'
      }
      return tn
    },
    isTransfer() {
      return this.type === historyTypeField.transferFromBranchToBranch || this.type === historyTypeField.transferFromStockToStore || this.type === historyTypeField.transferFromStoreToStock
    },
    placeholder() {
      let plh = ''
      if (this.type === historyTypeField.stockIn) {
        plh = 'Select Supplier'
      } else if (this.isTransfer) {
        if (this.branchType === branchTypeField.store) {
          plh = 'Select shop Branch'
        } else if (this.branchType === branchTypeField.stock) {
          plh = 'Select Warehouse Branch'
        }
      }
      return plh
    },
    filtered() {
      return this.options.filter(item => item.name.includes(this.search))
    },
    paginated() {
      return this.filtered.slice(this.offset, this.limit + this.offset)
    },
    hasNextPage() {
      const nextOffset = this.offset + this.limit
      return Boolean(this.options.slice(nextOffset, this.limit + nextOffset).length)
    },
    hasPrevPage() {
      const prevOffset = this.offset - this.limit
      return Boolean(this.options.slice(prevOffset, this.limit + prevOffset).length)
    },
    buildChange() {
      let nu
      if (this.qty && this.qty > 0) {
        if (!this.isStill) {
          nu = this.isMinus ? Math.max(Number(this.defaultValue) - Number(this.qty), 0) : Number(this.defaultValue) + Number(this.qty)
        } else {
          nu = this.qty
        }
      } else if (!this.isStill) {
        nu = Number(this.defaultValue)
      } else {
        nu = this.qty
      }
      return nu
    },
  },
  watch: {
    selected(v) {
      if (v) {
        this.secondInvalidFeedBack = ''
      }
    },
    textValue(v) {
      this.qty = v
    },
  },
  methods: {
    checkFormValidity() {
      let valid = this.$refs.form.checkValidity() && this.qty > 0
      this.qtyState = valid
      if (!valid) {
        this.invalidFeedBack = 'Invalid Quantity'
      } else {
        if (this.isForSale && !this.companyInfo[companyModelField.restrictSellOutOfStock]) {
          valid = true
          this.invalidFeedBack = ''
          return valid
        }
        this.invalidFeedBack = ''
        if (this.isMinus && Number(this.defaultValue) < this.qty) {
          valid = false
          this.invalidFeedBack = 'You don\'t have enough Quantity.'
          this.qtyState = valid
        }
        if (valid) {
          if (this.isTransfer && (!this.selected || this.selected === '')) {
            valid = false
            this.secondInvalidFeedBack = 'Please select branch to continue.'
          }
        }
      }

      return valid
    },
    resetModal() {
      this.qty = 0
      this.options = [] // via ref
      this.selected = ''
      this.secondInvalidFeedBack = ''
      this.invalidFeedBack = ''
      this.search = '' // via ref
      this.note = ''
      this.purchasePrice = null
      this.qtyState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    async handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }

      if (this.type === historyTypeField.stockIn || this.type === historyTypeField.stockOut) {
        // this.submitLoading = true

        this.$emit('onStockInAndOut',
          {
            qty: this.qty,
            purchasePrice: this.purchasePrice,
            expiryDate: this.expiryDate,
            note: this.note,
            supplierId: this.selected ? this.selected.id : null,
          })
        // this.submitLoading = false
      } else if (this.type === historyTypeField.transferFromBranchToBranch || this.type === historyTypeField.transferFromStockToStore || this.type === historyTypeField.transferFromStoreToStock) {
        this.$emit('onTransferFromBranchToBranch', { qty: this.qty, branch: this.selected ? this.selected : null })
      }
      this.resetModal()
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['stock-in-modal'].toggle('#toggle-btn')
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#modal-prevent-closing {
  .form-control {
    text-align: center;
  }

}
</style>
